import { ChatCompletionMessageParam } from "openai/resources";
import { AnimatedTerminalText } from "./AnimatedTerminalText";

export const Message: React.FC<
  ChatCompletionMessageParam & { animate?: boolean }
> = ({ role, content, animate = false }) => {
  const isUser = "user" === role;
  content = (isUser ? "> " : "") + content;
  content = content ? (isUser ? content.toUpperCase() : content) : "";

  return (
    <div className={isUser ? "text-active" : "text-main"}>
      {animate ? (
        <AnimatedTerminalText
          text={content}
          duration={25}
          showCursor={true}
        />
      ) : (
        <>{content}</>
        
      )}
    </div>
  );
};
