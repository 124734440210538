import { useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AdventureContext } from "../AdventureContext";

interface SettingsProps {
  exit: () => void;
}

export const Settings: React.FC<SettingsProps> = ({ exit }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { session } = useContext(AdventureContext);

  const refresh = useCallback(() => {
    // Navigate to the current path with a state change to trigger re-render
    const url = location.pathname;
    const withoutProtocol = url.replace(/^(https?:\/\/)?/, '');
    const parts = withoutProtocol.split('/');
    const base =  parts[0] + (parts[1] ? '/' + parts[1] : '');
    navigate(`${base}/${session}`, { state: { refresh: true } });
  }, [location.pathname, navigate, session]);

  const newStory = () => {
    const currentPath = location.pathname;
    const basePath = currentPath.substring(0, currentPath.lastIndexOf("/"));
    window.location.href = basePath;
  };

  return (
    <div onClick={exit} className="fixed w-screen h-screen flex justify-center align-center z-10 backdrop-blur-md">
      <div className="flex flex-col gap-16 w-full h-full justify-center items-center">
        <button onClick={refresh}>REFRESH</button>
        <button onClick={newStory}>NEW STORY</button>
      </div>
    </div>
  );
};
