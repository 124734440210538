import { useContext, useEffect, useState } from "react";
import { AdventureContext } from "./AdventureContext";
import { AnimatedText } from "./components/AnimatedText";
import { InkblotImage } from "./InkblotImage";
import { ChildrensSettings } from "./components/ChildrensSettings";
import { DarkModeContext } from "./DarkModeContext";

export const ChildrensBook: React.FC = () => {
  const {
    messages,
    options,
    choose: _choose,
    image,
    altText,
    loadingImage
  } = useContext(AdventureContext);

  const choose = (s: string) => {
    _choose(s);
  };

  const [showSettings, setShowSettings] = useState(false);

  const { darkMode } = useContext(DarkModeContext);
  const dark = "#000d";
  const textColor = darkMode ? "#ffffb5" : dark;
  const textShadowColor = darkMode ? "#0A0522" : "#fff";
  const textShadow = `0px 0px 10px ${textShadowColor}, 0px 0px 10px ${textShadowColor}, 0px 0px 10px ${textShadowColor}, 0px 0px 10px ${textShadowColor}, 0px 0px 10px ${textShadowColor}, 0px 0px 10px ${textShadowColor}, 0px 0px 10px ${textShadowColor}, 0px 0px 10px ${textShadowColor}, 0px 0px 10px ${textShadowColor}`;

  useEffect(() => {
    const style = document.createElement("style");

    style.innerHTML = `
      html, body {
        margin: 0;
        padding: 0;
        font-family: "IM FELL DW Pica", sans-serif;
        background-size: cover;
        color: ${textColor};
        text-shadow: 0 0 0.25px ${textShadowColor};
        background: ${darkMode ? "radial-gradient(#140A42, #000000)" : "white"};
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [darkMode, textColor, textShadowColor]);

  const lastMessage = messages[messages.length - 1];
  const content = lastMessage.role === "assistant" ? lastMessage.content : "";
  const duration = 1;

  return (
    <div className="relative w-screen h-screen">
      {showSettings && (
        <ChildrensSettings exit={() => setShowSettings(false)} />
      )}
      <div className="fixed top-0 w-full z-10 flex flex-row justify-center py-4">
        <button onClick={() => setShowSettings(true)}>
          Endless Adventures for Kids
        </button>
      </div>
      <div className="flex justify-center items-center w-full">
        <InkblotImage
          src={image}
          alt={altText}
          loading={loadingImage}
          className="h-[min(80vh, 100vw)] w-[min(80vh, 100vw)]"
        />
      </div>
      <div className="fixed bottom-0 w-screen flex flex-col text-xl sm:text-4xl p-16 gap-16">
        <div className="flex flex-row justify-center w-full">
          <div
            className="max-w-screen-lg text-center"
            style={{
              textShadow,
            }}
          >
            <AnimatedText text={content} duration={1}></AnimatedText>
          </div>
        </div>
        <div>
          <div className="align-bottom sm:px-8">
            <div className="flex flex-row w-full gap-16">
              {options.map((o, i) => (
                <button
                  key={i}
                  className="text-center im-fell-dw-pica-regular-italic flex-1"
                  onClick={() => choose(o)}
                  style={{
                    textShadow,
                  }}
                >
                  <AnimatedText text={o} duration={1}></AnimatedText>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
