import { useCallback, useContext, useEffect } from "react";
import { Library } from "./components/Library";
import { Character, amelia, eleazarito, nat } from "./shared/Characters";
import { AdventureContext } from "./AdventureContext";
import { ChildrensBook } from "./ChildrensBook";
import { DarkModeContext } from "./DarkModeContext";

export const Kids: React.FC = () => {
  const { session } = useContext(AdventureContext);

  return <>{session ? <ChildrensBook /> : <KidsLibrary />}</>;
};

export const KidsLibrary: React.FC = () => {
  const { createAdventure, choose } = useContext(AdventureContext);
  const { darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      html, body {
        margin: 0;
        padding: 0;
        font-family: "Comic Sans MS", sans-serif;
        background-size: cover;
        background: ${
          darkMode
            ? "radial-gradient(circle closest-side, #140A42, #000000)"
            : "white"
        };
        text-shadow: 0 0 0.25px #000d;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [darkMode]);

  const createKidAdventure = useCallback(
    async (character: Character) => {
      const id = await createAdventure({
        character,
        pointOfView: "third",
        media: "kids",
        tense: "past",
        sectionLength: "two sentences",
        optionCount: { min: 2, max: 2 },
        jokeFrequency: 1 / 3,
        optionLength: "<5 words",
        imageOptions: {
          style: {
            positive:
              "children's book illustration, colorful, whimsical, watercolor, Eric Carle style, disney",
            negative: "line art, ugly, boring, nsfw",
          },
          promptLength: 10,
          size: "square_hd",
        },
        targetAge: 6,
      });

      choose(character.name, id);
    },

    [choose, createAdventure]
  );

  const books = [
    {
      title: "Nat the Rat",
      aspectRatio: 1,
      imageUrl: darkMode ? "/covers/night/nat.jpeg" : "/covers/nat.jpeg",
      onClick: async () => {
        createKidAdventure(nat);
      },
    },
    {
      title: "El Changuito Eleazarito",
      aspectRatio: 1,
      imageUrl: darkMode
        ? "/covers/night/eleazarito.png"
        : "/covers/eleazarito.png",
      onClick: async () => {
        createKidAdventure(eleazarito);
      },
    },
    {
      title: "Amazing Amelia",
      aspectRatio: 1,
      imageUrl: darkMode ? "/covers/night/amelia.jpeg" : "/covers/amelia.jpeg",
      onClick: async () => {
        createKidAdventure(amelia);
      },
    },
  ];

  return (
    <div className="h-screen flex flex-col justify-center">
      <Library title="Kids" font="Comic Sans" books={books} />
    </div>
  );
};
