import clsx from "clsx";
import { motion } from "framer-motion";
import { Lorem } from "./Lorem";

export const Computer: React.FC<{ zoomed: boolean }> = ({ zoomed }) => {
  const screenWidthRatio = 479 / 1370;
  const screenHeightRatio = 306 / 857;
  const screenCenterRatio = { x: 576 / 1370, y: 251 / 857 };

  const computerWidthPx = window.innerWidth;
  const computerHeightPx = (computerWidthPx / 1370) * 857;
  const computerCenterPx = {
    x: computerWidthPx / 2,
    y: window.innerHeight - computerHeightPx / 2,
  };

  const screenWidthPx = screenWidthRatio * computerWidthPx;
  const screenHeightPx = screenHeightRatio * computerHeightPx;
  const screenCenterPx = {
    x: screenCenterRatio.x * computerWidthPx,
    y: screenCenterRatio.y * computerHeightPx,
  };

  const scale = Math.max(
    window.innerWidth / screenWidthPx,
    window.innerHeight / screenHeightPx
  );

  const zoomedScreenWidthPx = screenWidthRatio * scale;
  const zoomedComputerCenterPx = {
    x: window.innerWidth / 2,
    y: window.innerHeight - (computerHeightPx * scale) / 2,
  };
  const zoomedScreenCenterPx = {
    x:
      zoomedComputerCenterPx.x +
      scale * (0.5 - screenCenterRatio.x) * screenWidthPx,
    y:
      zoomedComputerCenterPx.y +
      scale * (0.5 - screenCenterRatio.y) * screenHeightPx,
  };

  const translateX = zoomedComputerCenterPx.x - zoomedScreenCenterPx.x;
  const translateY = zoomedComputerCenterPx.y - zoomedScreenCenterPx.y;

  return (
    <div className="absolute bottom-0 flex flex-col w-full">
      <div
        className="overflow-hidden flex flex-row justify-center w-full transition-all duration-[2s]"
        style={{
          transform: zoomed
            ? ` translateX(${
                (0.5 - screenCenterRatio.x) * 100
              }%) scale(${scale}) `
            : ``,
        }}
      >
        <div
          className="relative max-w-[600px] max-h-[50vh] bottom-0"
          style={{ aspectRatio: computerWidthPx / computerHeightPx }}
        >
          <div
            className="absolute bg-background text-[#28b5a4] font-mono text-[2px] sm:text-[3px]"
            style={{
              width: "42%",
              height: "45%",
              top: "7%",
              left: "22%",
            }}
          >
            <motion.div
              className={clsx(
                "relative transition-all duration-[2s]",
                zoomed ? "opacity-0" : "opacity-100"
              )}
              animate={{
                top: "-100%",
              }}
              transition={{
                duration: 0.01,
                repeat: Infinity,
                ease: "linear",
              }}
            >
              {Lorem}
            </motion.div>
          </div>
          <img alt="Computer" src="./computer.png" className="relative" />
        </div>
      </div>
      {/* <div className="bg-[#211217] active w-full h-24 flex flex-row justify-center">
        <img src="./coil-retro.svg" alt="COIL" className="pt-4 h-12"/>
      </div> */}
    </div>
  );
};
