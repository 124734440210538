import { useEffect, useRef, useState } from "react";
import { API_URL } from "../ApiClient";
import { ChatCompletionMessageParam } from "openai/resources";

interface SessionData {
    agent: {
      prompt: string;
      summarizer: string;
      fullHistory: ChatCompletionMessageParam[];
      history: ChatCompletionMessageParam[];
    };
}

export const Session: React.FC<{ id: string }> = ({ id }) => {
  const [expanded, setExpanded] = useState(false);
  const initialized = useRef(false);
  const [data, setData] = useState<SessionData>();

  const toggleExpanded = async () => {
    if (!initialized.current) {
      initialized.current = true;
      const response = await fetch(`${API_URL}/sessions/${id}`);
      const body = await response.json();
      setData(body);
    }
    setExpanded(!expanded);
  };

  return (
    <div onClick={toggleExpanded}>
      <div>
        {id} : {data ? data.agent.fullHistory[0].content : ""}
      </div>
      {expanded && (
        <>
          <div>
            <div className="bg-background">FULL HISTORY</div>
            {data?.agent.fullHistory.map((message) => (
              <div>{message.content}</div>
            ))}
          </div>
          <div>
          <div className="bg-background">HISTORY</div>
            {data?.agent.history.map((message) => (
              <div>{message.content}</div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export const Sessions: React.FC = () => {
  const [sessions, setSessions] = useState<string[]>([]);
  const initialized = useRef(false);

  useEffect(() => {
    (async () => {
      if (!initialized.current) {
        initialized.current = true;
        const response = await fetch(`${API_URL}/sessions`);
        const body = await response.json();
        setSessions(body);
      }
    })();
  });

  return (
    <div className="w-full h-full min-h-screen bg-background text-main">
      {sessions.map((id) => (
        <Session id={id} />
      ))}
    </div>
  );
};
