import { useEffect, useRef } from "react";

const config = {
  width: 749,
  height: 455,
  paths: [
    "M164.989 275.691C149.989 258.691 134.5 243.691 101.5 243.691C68.5 243.691 22 270.5 22 342.691C22 370.909 32.045 392.972 46.1799 408.191C61.0986 424.253 83.8013 432.691 109.989 432.691C116 432.691 207.5 439.691 223 331.191C235.4 244.391 299.834 238.691 330.5 246.691",
    "M222.989 335.691C222.989 418.891 282.156 433.024 310.489 431.691C363.989 431.691 395 386.5 395 340.5C395 304 387.489 259.5 328.989 246.691C292.989 315.692 442.689 310.291 489.489 236.691V366.691C489.489 413.191 513.089 447.291 561.489 423.691C621.989 394.191 708.989 263.191 718.989 139.691C726.989 40.8909 684.656 20.8576 662.489 23.1909C637.323 19.8577 590.889 58.8914 606.489 241.691C619.626 395.628 639.489 428.691 675.075 431.691C675.075 431.691 715.489 434.691 726.989 398.691",
  ],
};

interface LogoProps {
  speed?: number;
  color?: string;
}

export const CursiveLogoSVG: React.FC<LogoProps> = ({ speed = 1, color }) => {
  const pathRefs = useRef<(SVGPathElement | null)[]>([]);

  useEffect(() => {

    let lengthSoFar = 0;

    pathRefs.current.forEach((path, i) => {
      if (path) {
        const length = path.getTotalLength();
        path.style.strokeDasharray = length.toString();
        path.style.strokeDashoffset = length.toString();
        path.style.animation = `draw ${
          speed * length * 0.001
        }s linear forwards`;
        path.style.animationDelay = `${speed * (lengthSoFar * 1.2) * 0.001}s`;
        lengthSoFar += length;
      }
    });
  }, [speed]);

  return (
    <svg
      width={config.width}
      height={config.height}
      viewBox={`0 0 ${config.width} ${config.height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        ref={(el) => (pathRefs.current[0] = el)}
        d={config.paths[0]}
        stroke={color}
        stroke-width="44"
        stroke-linecap="round"
      />
      <path
        ref={(el) => (pathRefs.current[1] = el)}
        d={config.paths[1]}
        stroke={color}
        stroke-width="44"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="492.489" cy="139.191" r="33" fill={color} />
    </svg>
  );
};

export const CursiveLogoCanvas: React.FC<{ speed?: number, color: string }> = ({
  speed = 1,
  color = "white"
}) => {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = ref.current;
    if(!canvas) return;
    

    const ctx = canvas.getContext("2d");
    if(!ctx) return;
    ctx.imageSmoothingEnabled = false;


    const pathData1 =
      "M164.989 275.691C149.989 258.691 134.5 243.691 101.5 243.691C68.5 243.691 22 270.5 22 342.691C22 370.909 32.045 392.972 46.1799 408.191C61.0986 424.253 83.8013 432.691 109.989 432.691C116 432.691 207.5 439.691 223 331.191C235.4 244.391 299.834 238.691 330.5 246.691";
    const pathData2 =
      "M222.989 335.691C222.989 418.891 282.156 433.024 310.489 431.691C363.989 431.691 395 386.5 395 340.5C395 304 387.489 259.5 328.989 246.691C292.989 315.692 442.689 310.291 489.489 236.691V366.691C489.489 413.191 513.089 447.291 561.489 423.691C621.989 394.191 708.989 263.191 718.989 139.691C726.989 40.8909 684.656 20.8576 662.489 23.1909C637.323 19.8577 590.889 58.8914 606.489 241.691C619.626 395.628 639.489 428.691 675.075 431.691C675.075 431.691 715.489 434.691 726.989 398.691";

    const speed = 2; // Animation speed in seconds

    function drawPathWithAnimation(
      ctx: CanvasRenderingContext2D,
      pathData: string,
      delay: number
    ) {
      const path = new Path2D(pathData);
      const length = getPathLength(pathData);

      let startTime: number;

      function animate(timestamp: number) {
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime - delay * 1000;
        const progress = Math.min(elapsed / (speed * 1000), 1);


        ctx.strokeStyle = color;
        ctx.lineWidth = 44;
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.setLineDash([length]);
        ctx.lineDashOffset = length * (1 - progress);

        ctx.stroke(path);

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      }

      requestAnimationFrame(animate);
    }

    function getPathLength(pathData: string): number {
      const tempPath = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      tempPath.setAttribute("d", pathData);
      return tempPath.getTotalLength();
    }

    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      drawPathWithAnimation(ctx, pathData1, 0);
      setTimeout(() => {
      drawPathWithAnimation(ctx, pathData2, 0);
      }, speed * 1000);
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(492.489, 139.191, 33, 0, Math.PI * 2);
      ctx.fill();
    }
  }, [color]);


  return <canvas ref={ref} width={config.width} height={config.height} style={{width: config.width / 32, height: config.height / 32, transform: "scale(32)", transformOrigin: "top left", imageRendering: "pixelated"}}/>;
};
