import { useEffect, useState } from "react";
import { SplashScreen } from "./screens/SplashScreen";
import { BootScreen } from "./screens/BootScreen";
import { GameScreen } from "./screens/GameScreen";

export const Adventure: React.FC = () => {
  const [stage, setStage] = useState<"boot" | "splash" | "game">("boot");

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      html, body {
        margin: 0;
        padding: 0;
        background-color: #281519;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  });

  return (
    <>
      <div className="bg-background font-terminal md:text-xl text-main text-shadow">
        {stage === "boot" && <BootScreen onExit={() => setStage("splash")} />}
        {stage === "splash" && <SplashScreen onExit={() => setStage("game")} />}
        {stage === "game" && <GameScreen />}
      </div>
    </>
  );
};
