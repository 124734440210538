import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ChatCompletionMessageParam } from "openai/resources";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { AdventureOptions } from "./shared/AdventureOptions";
import { ApiClient, Instruction } from "./ApiClient";


interface AdventureContextProps {
  session: string | undefined;
  messages: ChatCompletionMessageParam[];
  options: string[];
  choose: (s: string, id?: string) => void;
  image: string | undefined;
  altText: string | undefined;
  loadingImage: boolean;
  createAdventure: (a: AdventureOptions) => Promise<string>;
  loadAdventure: (id: string) => void;
}

const configurations: { [s: string]: AdventureOptions } = {
  adventure: {
    pointOfView: "second",
    tense: "present",
    media: "adventure",
    sectionLength: "a paragraph",
    optionLength: "<10 words",
    jokeFrequency: 1 / 5,
    optionCount: { min: 2, max: 3 },
  },
  book: {
    pointOfView: "second",
    media: "book",
    tense: "present",
    sectionLength: "three paragraphs",
    optionLength: "<10 words",
    jokeFrequency: 1 / 4,
    optionCount: { min: 2, max: 3 },
    imageOptions: {
      style: {
        positive: "watercolor. black and white.",
        negative: "",
      },
      promptLength: 10,
      size: "landscape_16_9",
    },
  },
  kids: {
    pointOfView: "third",
    media: "kids",
    tense: "past",
    sectionLength: "two sentences",
    optionCount: { min: 2, max: 2 },
    jokeFrequency: 1 / 3,
    optionLength: "<5 words",
    imageOptions: {
      style: {
        positive:
          "children's book illustration. colorful. whimsical. watercolor. Eric Carle style. disney",
        negative: "line art. ugly. boring",
      },
      promptLength: 10,
      size: "square_hd",
    },
    targetAge: 6,
  },
};

const defaultState: AdventureContextProps = {
  session: undefined,
  messages: [],
  options: [],
  choose: () => null,
  image: undefined,
  altText: undefined,
  loadingImage: false,
  createAdventure: async () => "",
  loadAdventure: () => null,
};


export const AdventureContext =
  createContext<AdventureContextProps>(defaultState);

interface AdventureProviderProps {
  inputPrompts?: string[];
  startingText?: string;
  art?: {
    style: string;
    imageSize: string;
    useInkblot: boolean;
  };
  children: ReactNode;
}

export const AdventureProvider: React.FC<AdventureProviderProps> = ({
  art = undefined,
  inputPrompts,
  children,
  startingText = "Welcome to a world of infinite possibilities, where your imagination can soar to new heights and your choices shape the narrative. You are the protagonist in a realm where every decision opens up new paths and adventures. The world is yours... what adventure shall we explore?",
}) => {
  const initialized = useRef(false);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [session, setSession] = useState<string>(params.id || "");
  const [image, setImage] = useState<string | undefined>(undefined);
  const [altText, setAltText] = useState<string | undefined>("");
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [messages, setMessages] = useState<ChatCompletionMessageParam[]>([
          {
            role: "assistant",
            content: startingText,
          }]
  );

  const addMessage = (message: ChatCompletionMessageParam) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const addToLastMessage = (text: string) => {
    setMessages((prevMessages) => {
      if (prevMessages.length === 0) return prevMessages;

      const lastMessage = prevMessages[prevMessages.length - 1];
      if (lastMessage.role === "assistant") {
        return [
          ...prevMessages.slice(0, prevMessages.length - 1),
          {
            role: "assistant",
            content: `${lastMessage.content}${text}`,
          },
        ];
      } else {
        return [...prevMessages];
      }
    });
  };

  const createAdventure = useCallback(
    async (options?: AdventureOptions) => {
      const pathSegments = location.pathname
        .split("/")
        .filter((segment) => segment.trim() !== "");
      const type = `${pathSegments[0]}`;
      options = options || configurations[type];

      const { id } = await ApiClient.create(options);
      setSession(id);

      return id;
    },
    [location.pathname]
  );

  const loadAdventure = useCallback(async (id: string) => {
    const { latestImage, history } = await ApiClient.load(id);
    let latestOptions: string[] = [];

    const messages = history.map((m: ChatCompletionMessageParam) => {
      if (m.role === "assistant" && m.content) {
        const { text, options = [] } = JSON.parse(m.content);
        latestOptions = options;
        return {
          role: "assistant",
          content: text,
        };
      }
      return m;
    });

    if (messages.length > 0) {
      setMessages(messages as any);
      setOptions(latestOptions);
      setImage(latestImage);
    }

    ApiClient.subscribe(id, (i: Instruction) => {
      switch (i.instruction) {
        case "new_text":
          addMessage({ role: "assistant", content: "" });
          setOptions([]);
          break;
        case "add_to_text":
          addToLastMessage(i.data);
          setOptions([]);
          break;
        case "new_option":
          addOption("");
          break;
        case "add_to_option":
          addToLastOption(i.data);
          break;
        case "end":
          break;
        case "image_url":
          setImage(i.data);
          setLoadingImage(false);
          break;
        case "image_prompt":
          setAltText(i.data);
          break;
      }
    });
  }, []);

  const initializeSession = useCallback(
    async (_session?: string) => {
      _session = _session || session;
      if (_session) {
        try {
          await loadAdventure(_session);
        } catch (e) {
          const pathSegments = location.pathname
            .split("/")
            .filter((segment) => segment.trim() !== "");
          const type = `${pathSegments[0]}`;
          setSession("");
          // navigate(`/${type}`);
        }
      } else {
        // Do nothing. We now create adventure on choose
      }
    },
    [loadAdventure, location.pathname, session]
  );

  const choose = useCallback(
    async (choice: string, id?: string) => {
      console.log("Choose: " + choice);
      const _session = id || session || (await createAdventure());
      console.log("Session" + _session);

      await initializeSession(_session);

      setOptions([]);
      addMessage({ role: "user", content: choice });
      setLoadingImage(true);

      ApiClient.choose(_session, choice);
    },
    [createAdventure, initializeSession, session]
  );

  const samplePrompts = inputPrompts || [
    "I am Batman in the Marvel universe",
    "Explore a 1930's world fair",
    "Explore a dystopian cyberpunk future",
    "Visit Ancient Rome",
    "I am a new student at Hogwarts in the 1800s",
    "Hang out in Central Perk",
    "AI has overtaken the world and I am the last human",
    "I am a detective in a noir film",
  ];

  const selectThreeRandomPrompts = () => {
    const prompts = [...samplePrompts];
    const selectedPrompts = [];
    for (let i = 0; i < 3; i++) {
      const index = Math.floor(Math.random() * prompts.length);
      selectedPrompts.push(prompts[index]);
      prompts.splice(index, 1);
    }
    return selectedPrompts;
  };

  const [options, setOptions] = useState<string[]>(selectThreeRandomPrompts());

  const addOption = (option: string) => {
    setOptions((prevOptions) => [...prevOptions, option]);
  };

  const addToLastOption = (text: string) => {
    setOptions((prevOptions) => {
      if (prevOptions.length === 0) return prevOptions;

      const lastOption = prevOptions[prevOptions.length - 1];
      return [
        ...prevOptions.slice(0, prevOptions.length - 1),
        `${lastOption}${text}`,
      ];
    });
  };

  const optionsRef = useRef(options);

  // Ensure that optionsRef.current is always up to date
  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  useEffect(() => {
    if (!initialized.current) {
      initializeSession();
      initialized.current = true;
    }
  }, [initializeSession]);

  return (
    <AdventureContext.Provider
      value={{
        messages,
        options,
        choose,
        image,
        altText,
        loadingImage,
        loadAdventure,
        createAdventure,
        session,
      }}
    >
      {children}
    </AdventureContext.Provider>
  );
};
