import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { ASCIICursor } from "./ASCIICursor";

interface AnimatedTerminalTextProps {
  text: string | null;
  duration?: number;
  color?: string;
  showCursor?: boolean;
  cursorType?: "blinking_square" | "blinking_underline";
}

export const AnimatedTerminalText: React.FC<AnimatedTerminalTextProps> = ({
  text,
  duration = 25,
  showCursor = false,
  cursorType = "blinking_square",
}) => {
  const [charCount, setCharCount] = useState(0);

  // Every duration ms add a character to the text
  useEffect(() => {
    const interval = setInterval(() => {
      setCharCount((prev) => prev + 1);
    }, duration);
    return () => clearInterval(interval);
  }, [duration]);

  return (
    <>
      {text?.substring(0, charCount)}
      {/* {charCount % 2 ? "█" : " "} */}
      {showCursor && <ASCIICursor type={cursorType} />}
    </>
  );
};
