import clsx from "clsx";
import { useContext, useEffect, useRef, useState } from "react";
import { DarkModeContext } from "./DarkModeContext";

type MixBlendMode =
  | "normal"
  | "multiply"
  | "screen"
  | "overlay"
  | "darken"
  | "lighten"
  | "color-dodge"
  | "color-burn"
  | "hard-light"
  | "soft-light"
  | "difference"
  | "exclusion"
  | "hue"
  | "saturation"
  | "color"
  | "luminosity";

interface InkblotImageProps {
  src?: string;
  duration?: number;
  grayscale?: boolean;
  alt?: string;
  loading: boolean;
  className?: string;
}

const Inkblot: React.FC<{
  mixBlendMode: MixBlendMode;
  scale: number;
  reverse?: boolean;
  justifyContent?: "start" | "end" | "center";
  invertInkblot?: boolean;
}> = ({ mixBlendMode, scale, reverse, justifyContent, invertInkblot }) => {
  return (
    <div
      className="absolute w-full h-full transition-transform duration-[2000ms] mix-blend-multiply flex flex-row items-center"
      style={{
        mixBlendMode,
        transform: `scale(${scale})`,
        justifyContent,
      }}
    >
      <img
        src="/inkblot.jpeg"
        className={clsx(
          "h-full grayscale z-0 aspect-square",
          reverse ? "animate-slowSpinCcw" : "animate-slowSpin",
          invertInkblot ? "invert" : ""
        )}
        alt="inkblot"
      />
    </div>
  );
};

export const InkblotImage: React.FC<InkblotImageProps> = ({
  src,
  duration = 4,
  grayscale = false,
  alt = "",
  loading = false,
  className = "",
}) => {
  const [ newImageLoaded, setNewImageLoaded ] = useState(false);
  const { darkMode } = useContext(DarkModeContext);
  const scale = (loading || !newImageLoaded) ? 0.05 : 1;
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    setNewImageLoaded(false);

    const handleImageLoad = () => {
      setNewImageLoaded(true);
    };

    const image = imageRef.current;

    if (image) {
      if (image.complete) {
        // Image is already loaded
        handleImageLoad();
      } else {
        // Attach onLoad event listener to the image
        image.onload = handleImageLoad;
      }
    }

    // Clean up the onLoad event listener on component unmount or imageUrl change
    return () => {
      if (image) {
        image.onload = null;
      }
    };
  }, [src]);

  return (
    <div
      style={{ transform: "translate3d(0,0,0)" }}
      className={clsx(
        "relative overflow-hidden bg-[white] max-h-screen",
        grayscale ? "grayscale" : "",
        className,
        darkMode ? "mix-blend-lighten" : "mix-blend-multiply"
      )}
    >
      {!darkMode && (
        <div
          className={clsx(
            "bg-[white] absolute top-0 left-0 w-full h-full flex justify-center items-center z-30",
            "mix-blend-lighten"
          )}
        >
          <div
            className={clsx(
              "relative w-full h-full",
              darkMode ? "" : "mix-blend-color-normal"
            )}
          >
            <Inkblot
              mixBlendMode={"multiply"}
              scale={scale}
              justifyContent="start"
            />
            <Inkblot
              mixBlendMode={"multiply"}
              scale={scale}
              reverse
              justifyContent="center"
            />
            <Inkblot
              mixBlendMode={"multiply"}
              scale={scale}
              reverse
              justifyContent="end"
            />
          </div>
        </div>
      )}
      <img
        ref={imageRef}
        src={src}
        alt={alt}
        className={clsx("relative z-20", darkMode ? "mix-blend-multiply" : "")}
      />
      {darkMode && (
        <div
          className={clsx(
            "bg-[black] absolute top-0 left-0 w-full h-full flex justify-center items-center z-10"
          )}
        >
          <Inkblot
            mixBlendMode={"lighten"}
            scale={scale}
            justifyContent="start"
            invertInkblot
          />
          <Inkblot
            mixBlendMode={"lighten"}
            scale={scale}
            reverse
            justifyContent="center"
            invertInkblot
          />
          <Inkblot
            mixBlendMode={"lighten"}
            scale={scale}
            reverse
            justifyContent="end"
            invertInkblot
          />
        </div>
      )}
    </div>
  );
};
