import { CursiveLogoCanvas, CursiveLogoSVG } from "./components/CursiveLogo";

export const TestPage: React.FC = () => {
  

  return (
    <div className="w-screen h-screen flex flex-col bg-[black]">
      <CursiveLogoSVG color="white"/>
      {/* <InkblotImage src="/knight.png" loading={false} /> */}
      {/* <CursiveLogoCanvas /> */}
    </div>
  );
};
