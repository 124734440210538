import "./App.css";
import { Adventure } from "./Adventure";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Sessions } from "./components/Sessions";
import { AdventureProvider } from "./AdventureContext";
import { Bottomless } from "./components/Bottomless";
import { Book } from "./Book";
import { TestPage } from "./TestPage";
import { Kids } from "./Kids";
import { DarkModeProvider } from "./DarkModeContext";
// import { Draw } from "./Draw";

const App = () => {
  return (
    <DarkModeProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div className="w-screen h-screen flex flex-col gap-4 justify-center items-center">
                <a href="/endless">game</a>
                <a href="/book">book</a>
                <a href="/kids">kids</a>
              </div>
            }
          />
          <Route
            path="/adventure/:id?"
            element={
              <AdventureProvider>
                <Adventure />
              </AdventureProvider>
            }
          />
          <Route path="/sessions" element={<Sessions />} />
          <Route path="/endless" element={<Bottomless />} />
          <Route
            path="/book/:id?"
            element={
              <AdventureProvider
                inputPrompts={[
                  "I'm a detective in a pulpy noir story",
                  // "I've just gotten into Hogwarts",
                  "Tell me a female spy thriller",
                  "Explore a thrilling fantasy adventure",
                  "AI has taken over the world",
                  "Solve a murder at a mansion",
                  "Romance novel",
                ]}
                art={{
                  style:
                    "2d line art illustration. black and white. minimalist. white background",
                  imageSize: "medium",
                  useInkblot: true,
                }}
              >
                <Book />
              </AdventureProvider>
            }
          />
          <Route
            path="/kids/:id?"
            element={
              <AdventureProvider
                art={{
                  style: "children's book, colorful, Eric Carle",
                  imageSize: "large",
                  useInkblot: false,
                }}
              >
                <Kids />
              </AdventureProvider>
            }
          />
          <Route path="/test" element={<TestPage />} />
          {/* You can add a default route or a 404 page here if needed */}
        </Routes>
      </Router>
    </DarkModeProvider>
  );
};

export default App;
