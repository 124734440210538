import clsx from "clsx";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Computer } from "./Computer";
import { useNavigate } from "react-router-dom";

const ImageNames = [
  "bond",
  "karate",
  "mario",
  "aztec",
  "devil",
  "mushrooms",
  "yakuza",
  "scifi",
  "doctor",
  "friends",
  "mummy",
  "twilight",
  "pirates",
  "mars",
  "moon",
  "ufo",
  "girl",
  "castle",
  "western",
  "gay",
] as const;
type ImageName = (typeof ImageNames)[number];

export const Bottomless: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);

  return (
    <Hero
      expanded={expanded}
      onClick={() => {
        setExpanded((prev) => !prev);
        setTimeout(() => {
          navigate("/adventure");
        }, 2000);
      }}
    />
  );
};

const Sun: React.FC = () => (
  <div
    className="w-full aspect-square absolute top-[33%] flex flex-row"
    style={{ transform: "translateY(-50%) scale(1.5)" }}
  >
    <div className="">
      <SunLayer name={"./sun-4.png"} duration={50} scale={1.5} />
      <SunLayer name={"./sun-3.png"} duration={60} scale={1.4} />
      <SunLayer name={"./sun-2.png"} duration={70} scale={1.3} />
      <SunLayer name={"./sun-1.png"} duration={80} scale={1.2} />
      <SunLayer name={"./sun-0.png"} duration={90} scale={1.1} />
      <SunLayer name={"./sun.png"} duration={100} scale={1.2} />
    </div>
  </div>
);

const SunLayer: React.FC<{
  name: string;
  duration: number;
  scale: number;
}> = ({ name, duration, scale }) => (
  <motion.img
    animate={{ rotate: [0, 180, 360], scale: [1, scale, 1] }}
    transition={{ duration, repeat: Infinity, ease: "linear" }}
    src={name}
    alt=""
    className="absolute w-full"
  />
);

const Grid: React.FC = () => (
  <div
    className="absolute bottom-0 w-full h-[40%] flex flex-row justify-center"
    style={{
      background: "url(./grid.png)",
      backgroundSize: "cover",
      backgroundPositionX: "center",
      backgroundPositionY: "top",
    }}
  >
    {/* <img className="relative w-[10vw] -top-[3vw] h-[5vw]" src="./shine.png"/> */}
  </div>
);

const Hero: React.FC<{
  expanded: boolean;
  onClick: () => void;
}> = ({ expanded, onClick }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [innerWidth, setInnerWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setInnerWidth(ref.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="w-full h-full relative overflow-hidden"
      onClick={onClick}
    >
      <div className={clsx("h-full w-full")}>
        <div
          ref={ref}
          className={clsx("h-full w-full overflow-hidden relative")}
          style={{
            background: "url('./space.png')",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Sun />
          <div className="absolute top-0 left-0 w-full flex flex-row justify-center p-8">
            <img className="w-full" alt="Endless Adventure" src="./ea-logo.svg"/>
          </div>
          <Grid />
          <Computer zoomed={expanded} />
        </div>
      </div>
    </div>
  );
};
