import { useEffect, useState } from "react";

interface AnimatedTextProps {
  text: string | null;
  duration?: number;
}

function replaceStraightQuotes(str: string) {
  let result = '';

  for (let i = 0; i < str.length; i++) {
    const prev = i > 0 ? str[i - 1] : '';
    const char = str[i];
    const next = i < str.length - 1 ? str[i + 1] : '';

    if (char === "'") {
      if (prev === ' ' || prev === '') {
        result += "‘";
      } else {
        result += "'";
      }
    } else if (char === '"') {
      if (prev === ' ' || prev === '') {
        result += "“";
      } else {
        result += '"';
      }
    } else {
      result += char;
    }
  }

  return result;
}

export const AnimatedText: React.FC<AnimatedTextProps> = ({
  text,
  duration = 2500,
}) => {
  const [charCount, setCharCount] = useState(0);
  text = replaceStraightQuotes(text || '');
  
  // Every duration ms add a character to the text
  useEffect(() => {
    const interval = setInterval(() => {
      setCharCount((prev) => prev + 1);
    }, duration);
    return () => clearInterval(interval);
  });
  return (
    <>
      {text
        ? text.substring(0, charCount).split("").map((char, i) => (
            <span className="animated-character" key={i}>
              {char}
            </span>
          ))
        : ""}
        <span className="opacity-0">
          { text?.substring(charCount)}
        </span>
    </>
  );
};
