import { useEffect, useRef, useState } from "react";
import { sys } from "typescript";

type CursorType = "blinking_square" | "blinking_underline";

export const ASCIICursor: React.FC<{type: CursorType}> = ({type}) => {
  const initialized = useRef(false);
  const duration = 500;
  const [frame, setFrame] = useState(0);
  const symbolMap = {
    "blinking_square": "█",
    "blinking_underline": "_",
  };
  const symbol = symbolMap[type];

  // Every duration ms add a character to the text
  useEffect(() => {
    const interval = setInterval(() => {
      setFrame((prev) => prev + 1);
    }, duration);
    return () => clearInterval(interval);
  }, [frame]);

  return <span className={(frame % 2) ? "opacity-0" : "opacity-100"}>
    {symbol}
  </span>
};
