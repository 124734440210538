export interface Clothing {
  type: string;
  color?: string;
}

export interface Character {
  name: string;
  boringName: string;
  type: "animal" | "human";
  clothing?: Clothing[];
  personality?: string;
  brandColor: string;
  imageUrl?: string;
  about?: string;
}

export interface Animal extends Character {
  type: "animal";
  species?: string;
  subSpecies?: string;
  color?: string;
}

export interface Human extends Character {
  type: "human";
  age: number;
  hairColor: string;
  ethnicity?: string;
  gender?: string;
}

export const ryan: Human = {
  age: 47,
  name: "Ryan the Ronin",
  boringName: "Ryan",
  type: "human",
  hairColor: "blonde",
  personality: "brave, loyal, and a little bit of a loner",
  brandColor: "gray"
};

export const eleazarito: Animal = {
  name: "El Changuito Eleazarito",
  boringName: "Eleazarito",
  type: "animal",
  species: "chango",
  personality: "inteligente, travieso, y un poco loquito",
  brandColor: "green",
  about: "El Changuito Eleazarito is a mischevious little monkey living in the jungle. He is clever but also very silly, always finding himself wanting life to be more exciting. He balances wanting to help all the other monkeys will just maximizing his own fun. He can be a little cranky but ultimately has a heart of goal."
};

export const marthita: Human = {
  name: "Marthita la Maravilla",
  boringName: "Marthita",
  type: "human",
  hairColor: "brown",
  personality: "smart, always right, but a little bossy",
  brandColor: "red",
  about: "Marthita is a smart girl growing up in Mexico. She always wants the best for everyone but can be a little bossy. She is always right and always has a plan. She is a little bossy but ultimately has a heart of gold.",
  age: 38
}

export const amelia: Human = {
  age: 6,
  name: "Amazing Amelia",
  boringName: "Amelia",
  type: "human",
  hairColor: "blonde",
  personality: "curious, adventurous, and a little bit of a troublemaker",
  brandColor: "lightskyblue",
  about:"Amazing Amelis is a cursious young girl who mostly keeps to herself but always finds adventures. She is endlessly curious (staring into the sky) and finds magic wherever she goes. She is a little shy but also a genius, who sometimes feels comfortable bragging a lot."
}

export const koko: Animal = {
  name: "Koko the Koala",
  boringName: "Koko",
  type: "animal",
  species: "koala",
  color: "gray",
  personality: "sleepy, cuddly, and a little bit of a picky eater",
  brandColor: "gray"
}

export const koven: Animal = {
  name: "Koven the Dragon",
  boringName: "Koven",
  type: "animal",
  species: "dragon",
  color: "blue",
  personality: "fierce, loyal, and a little bit of a showoff",
  brandColor: "blue"
}

// export const elMosquitoAventurero: Animal = {
//   name: "El Mosquito Aventurero",
//   boringName: "El Mosquito Aventurero",
//   type: "animal",
//   species: "mosquito",
//   personality: "molesto, hambriento, y un poco de un fastidio",
//   clothing: [
//     {
//       type: "captain's hat",
//       color: "blue",
//     },
//   ],
//   brandColor: "navy"
// }

export const nat: Animal = {
  name: "Nat the Rat",
  boringName: "Nat",
  type: "animal",
  species: "rat",
  color: "gray",
  personality: "curious, adventurous, and a little bit of a troublemaker",
  brandColor: "gray",
  about: "Nat the Rat is a simple and curious rat who lives in a rat hole in a small town, near a cheese factory. He goes on simple adventures that are always fun and exciting. He is not the smart, the toughest, or the bravest, but he always finds himself in interesting situations."
}

// export const danny: Human = {
//   age: 6,
//   name: "Danny the Doer",
//   boringName: "Danny",
//   type: "human",
//   hairColor: "brown",
//   personality: "curious, adventurous, and a little bit of a troublemaker",
//   brandColor: "orange"
// }

export const danny: Animal = {
  name: "Danny the Dinosaur",
  boringName: "Danny",
  personality: "curious, adventurous, and a little bit of a troublemaker",
  brandColor: "orange",
  type: "animal",
  species: "dinosaur",
  subSpecies: "stegosaurus",
  color: "orange"
}

export const victor: Animal = {
  name: "Victor the Very Nice Penguin",
  boringName: "Victor",
  personality: "shy, kind, and secretlye adventurous",
  brandColor: "orange",
  type: "animal",
  species: "penguin",
  subSpecies: "emperor",
  color: ""
}

export const sam: Animal = {
  name: "Sly Sam",
  boringName: "Sam",
  type: "animal",
  species: "fox",
  color: "orange",
  personality: "curious, adventurous, ambitious",
  brandColor: "orange"
}

export const characters = [ryan, eleazarito, amelia, koko, koven, /*elMosquitoAventurero, */ nat, danny, sam];