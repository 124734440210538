import { useEffect, useRef, useState } from "react";
import { AnimatedTerminalText } from "../components/AnimatedTerminalText";
import { AnimatedText } from "../components/AnimatedText";
import { ASCIICursor } from "../components/ASCIICursor";

export const BootScreen: React.FC<{ onExit: () => void }> = ({ onExit }) => {
  const initialized = useRef(false);
  const interval = useRef<NodeJS.Timer>();
  const timeout = useRef<NodeJS.Timer>();
  const duration = 20;
  const cycleLength = 1000;
  const finishedCycles = 0;

  const smallCoilLogo =
    "  █████    █████    ██████  ██████  \n" +
    "██       ██     ██    ██      ██    \n" +
    "██       ██     ██    ██      ██  ██\n" +
    "  █████    █████    ██████  ████████\n";

  const mediumCoilLogo =
    "  ████████      ████████    ████████  ████████      \n" +
    "████████████  ████████████  ████████  ████████      \n" +
    "████          ████    ████    ████      ████        \n" +
    "████          ████    ████    ████      ████    ████\n" +
    "████████████  ████████████  ████████  ██████████████\n" +
    "  ████████      ████████    ████████  ██████████████\n";

  const largeCoilLogo =
    "           ██████████                    ██████████            ██████████████████   ██████████████████        \n" +
    "       ██████████████████            ██████████████████        ██████████████████   ██████████████████        \n" +
    "    ██████           ██████       ██████            ██████           ██████               ██████              \n" +
    "  ██████               ██████   ██████                ██████         ██████               ██████              \n" +
    "  ██████                        ██████                ██████         ██████               ██████              \n" +
    "  ██████                        ██████                ██████         ██████               ██████              \n" +
    "  ██████               ██████   ██████                ██████         ██████               ██████              \n" +
    "    ██████           ██████       ██████            ██████           ██████               ██████      ██████  \n" +
    "       █████████████████             ███████████████████       ██████████████████   ████████████████████████  \n" +
    "           █████████                     ██████████            ██████████████████   ████████████████████████ ®\n";

  const [coilLogo, setCoilLogo] = useState<string>();

  const coilText = "Copyright (C) Coil Inc, 2024\nAll Rights Reserved\n";

  const lines = [
    "Booting CoilOS...",
    "Loading Kernel...",
    "Loading Drivers...",
    "Loading Images...",
    "Loading Music...",
    "Loading System...",
    "Loading Network...",
    "Loading Artificial Intelligence...",
    "Loading Adventure Game Interpreter...",
    "Loading All Human Knowledge...",
    "Exiting Reality...",
  ];

  const [linesToShow, setLinesToShow] = useState(0);

  useEffect(() => {
    const eventTypes = ["keypress", "mouseup", "touchend"];
  
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1120) {
        setCoilLogo(largeCoilLogo);
      } else if (width >= 500) {
        setCoilLogo(mediumCoilLogo);
      } else {
        setCoilLogo(smallCoilLogo);
      }
    };

    clearTimeout(interval.current!);
    interval.current = setInterval(() => {
      setLinesToShow((prev) => Math.min(prev + 1, lines.length));
    }, cycleLength);

    clearTimeout(timeout.current!);
    timeout.current = setTimeout(onExit, cycleLength * (lines.length + finishedCycles))

    handleResize();
    window.addEventListener("resize", handleResize);

    eventTypes.forEach((eventType) => {
      window.addEventListener(eventType, onExit);
    });

    return () => {
      clearTimeout(interval.current!);
      clearTimeout(timeout.current!);
      eventTypes.forEach((eventType) => {
        window.removeEventListener(eventType, onExit);
      });
      window.removeEventListener("resize", handleResize);
    }
  }, [largeCoilLogo, lines.length, linesToShow, mediumCoilLogo, onExit, smallCoilLogo]);

  return (
    <div className="whitespace-pre p-4  min-h-screen">
      <div className="text-center">
        <br />
        <span style={{ letterSpacing: "0" }}>{coilLogo}</span>
        <br />
        <span className="text-active">{coilText}</span>
        <br />
      </div>
      {lines.slice(0, linesToShow).map((line, i) => (
        <div>
          <AnimatedTerminalText text={line} duration={duration} />
          {(i === linesToShow - 1) && <ASCIICursor type="blinking_square" />}
        </div>
      ))}
    </div>
  );
};
