import {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AdventureContext } from "../AdventureContext";
import clsx from "clsx";

export const UserForm: React.FC<{
  submitText: (s: string) => void;
  options: string[];
}> = ({ submitText: onSubmit, options }) => {
  const initialized = useRef(false);
  const { choose: _choose } = useContext(AdventureContext);
  const [text, setText] = useState("");
  const optionsRef = useRef(options);
  const [activeOption, setActiveOption] = useState(0);
  const activeOptionRef = useRef(activeOption);
  const focus = activeOption > 0 && activeOption === options.length;
  const textArea = useRef<HTMLTextAreaElement>(null);
  const optionRefs = useRef<React.RefObject<HTMLButtonElement>[]>([]);
  const [textAreaHover, setTextAreaHover] = useState(false);

  const choose = useCallback(
    (s: string) => {
      _choose(s);
      setText("");
      setActiveOption(0);
    },
    [_choose, setText, setActiveOption]
  );

  const keyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey && text.trim() !== "") {
      e.preventDefault();
      onSubmit(text);
      setText("");
    }
  };

  useEffect(() => {
    optionsRef.current = options;
    if (options && typeof options === "object" && options.length > 0) {
      optionRefs.current = options.map(
        (_, i) => optionRefs.current[i] || createRef()
      );
    }
  }, [options]);

  useEffect(() => {
    activeOptionRef.current = activeOption;
    optionRefs.current[activeOption]?.current?.focus();
  }, [activeOption]);

  useEffect(() => {
    if (focus && textArea.current) {
      textArea.current.focus();
    } else if (!focus) {
      textArea.current?.blur();
    }
  }, [focus]);

  useEffect(() => {
    if (initialized.current) return;

    initialized.current = true;
    window.addEventListener("keydown", (ev) => {
      if (["1", "2", "3", "4"].includes(ev.key)) {
        const activeElement = document.activeElement;

        if (
          activeElement &&
          activeElement.tagName === "TEXTAREA" &&
          activeElement.innerHTML !== ""
        ) {
        } else {
          ev.preventDefault();
          const index = parseInt(ev.key) - 1;
          if (index < optionsRef.current.length) {
            console.log("Choosing from optionsRef: " + optionsRef.current);
            choose(optionsRef.current[index]);
          }
        }
      } else if (ev.key === "ArrowUp") {
        setActiveOption((prev) => Math.max(0, prev - 1));
        ev.preventDefault();
      } else if (ev.key === "ArrowDown") {
        setActiveOption((prev) =>
          Math.min(optionsRef.current.length, prev + 1)
        );
        ev.preventDefault();
      } else if (ev.key === "Enter") {
        if (optionsRef.current.length === 0) return;
        if (activeOptionRef.current >= optionsRef.current.length) return;

        choose(optionsRef.current[activeOptionRef.current]);
        ev.preventDefault();
      } else {
        const keysToIgnore = ["Shift", "Control", "Alt", "Meta", "Tab"];
        if (keysToIgnore.includes(ev.key)) return;

        setActiveOption(optionsRef.current.length);
      }
    });
  }, [activeOption, choose, onSubmit, optionsRef]);

  return (
    <div className="flex flex-col gap-4 overflow-hidden">
      <span>
        ════════════════════════════════════════════════════════════════════════════════════
      </span>
      <>
        <div className="flex flex-col justify-evenly gap-4">
          {options &&
            options.length &&
            options.map((option, i) => (
              <button
                key={i}
                ref={optionRefs.current[i]}
                type="submit"
                onFocus={() => setActiveOption(i)}
                onMouseEnter={() => setActiveOption(i)}
                className={clsx(
                  "flex rounded-xl text-left align-text-top justify-start transition-all",
                  "flex gap-4 transition-all duration-250 outline-none focus:outline-none",
                  i === activeOption ? "text-active" : "text-main"
                )}
                onClick={() => choose(option)}
              >
                <div className="">{i === activeOption ? ">" : i + 1}</div>
                {option}
              </button>
            ))}
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(text);
            setText("");
          }}
          className={clsx(
            "flex flex-row p-3 relative w-full"
            // focus ? "shadow-[0_0_20px_5px]" : "shadow-none"
          )}
        >
          <textarea
            placeholder={focus ? "" : "Do anything you can think of"}
            ref={textArea}
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            onKeyDown={keyPress}
            onMouseEnter={() => {
              setTextAreaHover(true);
              setActiveOption(-1);
            }}
            onMouseLeave={() => {
              setTextAreaHover(false);
            }}
            className={clsx(
              "flex-grow focus:outline-none resize-none bg-[transparent]",
              focus || textAreaHover
                ? "text-active placeholder:text-active"
                : "text-main placeholder:text-main"
            )}
            onFocus={(e) => setActiveOption(options.length)}
          ></textarea>
        </form>
      </>
    </div>
  );
};
