import { ChatCompletionMessageParam } from "openai/resources";
import { AdventureOptions } from "./shared/AdventureOptions";

export const API_URL = process.env.REACT_APP_API_URL;

export type Instruction = {
  instruction:
    | "new_adventure"
    | "load_adventure"
    | "new_text"
    | "add_to_text"
    | "new_option"
    | "add_to_option"
    | "end"
    | "choose"
    | "sessions"
    | "image_url"
    | "image_prompt";
  data?: any;
};

interface IApiClient {
  create: (options: AdventureOptions) => Promise<{id: string}>;
  load: (id: string) => Promise<{history: ChatCompletionMessageParam[], latestImage: string}>;
  subscribe: (id: string, callback: (data: any) => void) => void;
  choose: (id: string, choice: string) => void;
}

let heartbeat: NodeJS.Timer | null = null;
let eventSource: EventSource | null = null;

export const ApiClient: IApiClient = {
  create: async (options: AdventureOptions) => {
    const res = await fetch(`${API_URL}/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(options),
    });
    return res.json();
  },

  load: async (id: string) => {
    const res = await fetch(`${API_URL}/load/${id}`);
    return res.json();
  },
  
  subscribe: (id: string, callback: (data: Instruction) => void) => {
    if(eventSource) eventSource.close();
    if(heartbeat) clearInterval(heartbeat);
    
    eventSource = new EventSource(`${API_URL}/subscribe/${id}`);
    eventSource.onmessage = (e) => {
      const instructions = JSON.parse(e.data) as Instruction[];
      if(instructions.length === 0) return;

      instructions.forEach((i) => {
        callback(i);
      });
    };

    // Set up a heartbeat to keep the connection alive
    heartbeat = setInterval(() => {
      fetch(`${API_URL}/heartbeat`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
    }, 40 * 1000);
  },
  
  choose: (id: string, choice: string) => {
    fetch(`${API_URL}/choose/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ choice }),
    });
  },
};