import { useContext, useEffect, useState } from "react";
import { AdventureContext } from "./AdventureContext";
import { AnimatedText } from "./components/AnimatedText";
import { InkblotImage } from "./InkblotImage";
import { Settings } from "./components/Settings";
import { DarkModeContext } from "./DarkModeContext";

export const Book: React.FC = () => {
  const {
    messages,
    options,
    choose: _choose,
    image,
    altText,
    loadingImage,
  } = useContext(AdventureContext);

  const { darkMode } = useContext(DarkModeContext);

  const choose = (s: string) => {
    _choose(s);
  };

  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      html, body {
        margin: 0;
        padding: 0;
        font-family: "IM FELL DW Pica", sans-serif;
        color: ${darkMode ? "#fffc" : "#000d"};
        text-shadow: 0 0 0.25px #000d;
      }

      body::before {
        transition: filter 0.5s;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('/paper.jpg');
        background-size: cover;
        filter: invert(${
          darkMode ? "100%" : "0%"
        }); /* Inverts the colors of the background image */
        z-index: -1; /* Ensures the background image is behind the content */
        box-shadow: 0px 0px 75px white inset, 0px 0px 75px white inset, 0px 0px 75px white inset;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [darkMode]);

  const lastMessage = messages[messages.length - 1];
  const content = lastMessage.role === "assistant" ? lastMessage.content : "";

  return (
    <div className="w-screen h-screen flex flex-row justify-center items-center" >
      {showSettings && <Settings exit={() => setShowSettings(false)} />}
      {/* {darkMode && <div className="fixed top-0 left-0 w-screen h-screen bg-cover bg-center scale-150 pointer-events-none z-50 mix-blend-multiply" style={{backgroundImage: "url('/flashlight.png')"}}></div>} */}
      <div className="text-xl sm:text-2xl flex flex-col h-[1000px] max-h-screen p-4 w-full max-w-screen-md">
        <div className="w-full text-center scale-75">
          <button onClick={() => setShowSettings(true)}>
            ENDLESS ADVENTURES
          </button>
        </div>
        <div className="font-book flex-grow">
          <div className="flex flex-col gap-4 md:gap-16">
            {/* <div className={darkMode ? "mix-blend-lighten" : "mix-blend-multiply"}> */}
              <InkblotImage
                src={image ||  `/covers/books/${Math.floor((Math.random() * 13))}.jpeg`}
                grayscale
                alt={altText}
                loading={loadingImage}
              />
            {/* </div> */}

            <div className="sm:px-8">
              <AnimatedText text={content} duration={1}></AnimatedText>
            </div>
          </div>
          {/* {messages.map((m, i) => {
          return (
            <div key={i} className="text-2xl">
              {m.content}
            </div>
          );
        })} */}
        </div>
        <div className="align-bottom sm:px-8">
          <hr />
          <div className="flex flex-col gap-3 pt-3 pb-6 sm:pb-0">
            {options.map((o, i) => (
              <button
                key={i}
                className="text-right im-fell-dw-pica-regular-italic"
                onClick={() => choose(o)}
              >
                <AnimatedText text={o} duration={1}></AnimatedText>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
