import { useContext, useEffect, useState } from "react";
import { AdventureContext } from "../AdventureContext";
import { Message } from "../components/Message";
import { Loading } from "../components/Loading";
import { UserForm } from "../components/UserForm";
import clsx from "clsx";
import { TerminalSettings } from "../components/TerminalSettings";

export const GameScreen: React.FC = () => {
  const { messages, options, choose } = useContext(AdventureContext);
  const loading = messages.length >= 1 && messages[messages.length - 1].role === "user";
  const [ showSettings, setShowSettings ] = useState(false);

  useEffect(() => {
    if (messages.length > 2) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, options]);

  let lastUserIndex = 0;
  for (let i = messages.length - 1; i >= 0; i--) {
    if (messages[i].role === "user") {
      lastUserIndex = i;
      break;
    }
  };

  return (
    <div className="flex flex-row justify-center w-screen bg-background min-h-screen">
      <div className="flex flex-col gap-4 px-4 w-full max-w-screen-md pt-16">
        <div className="bg-main fixed left-0 top-0 w-full text-active text-center z-30">
          <button className="text-active" onClick={() => setShowSettings(true)}>ENDLESS ADVENTURE</button>
        </div>
        {showSettings && <TerminalSettings exit={() => setShowSettings(false)} />}
        {/* <div className="whitespace-pre leading-4 text-active">
          {
`╔═══════════════════════════════════════════════════╗
║                                                   ║
║                 ENDLESS ADVENTURE                 ║
║                                                   ║
╠═══════════════════════════════════════════════════╣
║ (c) Copyright Coil Inc. 2024, All Rights Reserved ║
╚═══════════════════════════════════════════════════╝`}
        </div> */}
        {messages.slice(0, lastUserIndex).map((m, i) => (
          // <Message key={i} animate={i === messages.length - 1} {...m} />
          <Message key={i} {...m} animate={false} />
        ))}
        <div className={clsx(
          "flex flex-col py-4",
          messages.length <= 2 ? "h-full" : "min-h-[80vh]"
          )}>
          <div className="flex flex-col flex-grow gap-4">
            {messages.slice(lastUserIndex).map((m, i) => (
              <Message key={i} {...m} animate={m.role === 'assistant'}/>
            ))}
          </div>
          <div>
            {loading && <Loading />}
            <div className="h-20"></div>
            <UserForm
              submitText={(s) => choose(s)}
              options={options}
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};