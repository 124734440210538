import { motion } from "framer-motion";

export const Loading: React.FC = () => (
  <div className="flex flex-row justify-center">
    {[0, 1, 2].map((i) => (
      <motion.span
        className="relative font-black "
        animate={{
          bottom: [0, 5, 0],
          opacity: [1, 0.5],
        }}
        transition={{
          duration: 1,
          delay: i * 0.25,
          repeat: Infinity,
          repeatDelay: 0.4,
        }}
        key={i}
      >
        .{" "}
      </motion.span>
    ))}
  </div>
);
