import { useEffect, useRef, useState } from "react";
import { InkblotImage } from "../InkblotImage";

export interface Book {
  title: string;
  aspectRatio: number;
  imageUrl: string;
  titleColor?: string;
  onClick: () => void;
}

const size = 80;
const gap = 5;
const paddingLeft = 100 - size - gap * 2;

export const Cover: React.FC<Book> = ({
  title,
  aspectRatio,
  imageUrl,
  titleColor,
  onClick,
}) => {
  return (
    <div
      className="flex-none snap-center rounded-md overflow-hidden relative"
      style={{
        aspectRatio,
        color: titleColor,
        background: `url(${imageUrl}) center center / cover no-repeat`,
        width: `${size}vw`,
        maxWidth: 500,
        maxHeight: `80vh`,
        // border: '1px solid red'
      }}
      onClick={onClick}
    >
      {/* <InkblotImage src={imageUrl} loading={false} /> */}
      <h2
        className="absolute w-full z-10 bottom-4 text-center text-5xl im-fell-dw-pica-regular"
        style={{
          textShadow:
            "0px 0px 10px white, 0px 0px 10px white, 0px 0px 10px white, 0px 0px 10px white, 0px 0px 10px white, 0px 0px 10px white, 0px 0px 10px white, 0px 0px 10px white, 0px 0px 10px white",
        }}
      >
        {title}
      </h2>
    </div>
  );
};

interface LibraryProps {
  title: string;
  font: string;
  books: Book[];
}

export const Library: React.FC<LibraryProps> = ({ title, font, books }) => {
  const ref = useRef<HTMLDivElement>(null);

  const onWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (!ref.current) return;
    const { deltaY } = e;
    ref.current.scrollLeft += deltaY; // Translate vertical scrolling to horizontal
  };
  
  return (
    <div className="flex flex-col items-center">
      {/* <h1 style={{ font }}>{title}</h1> */}
      <div
        className="flex overflow-x-auto w-screen"
        style={{
          gap: `${gap}vw`,
          paddingLeft: `${paddingLeft}vw`,
          paddingRight: `${paddingLeft}vw`,
        }}
        ref={ref}
        onWheel={onWheel}
      >
        {books.map((book, index) => (
          <Cover key={index} {...book} />
        ))}
      </div>
    </div>
  );
};
