import { createContext, useEffect, useState } from "react";

type TimeOfDay = "morning" | "afternoon" | "evening" | "night" | "sleep";

interface ThemedStyle {
  base: string;
  light?: string;
  dark?: string;
}

interface DarkModeContextProps {
  darkMode: boolean;
}

const defaultState: DarkModeContextProps = {
  darkMode: false,
};

export const DarkModeContext = createContext<DarkModeContextProps>(defaultState);

export const DarkModeProvider: React.FC<{children: any}> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    // NOTE: This does not work on iOS PWA :(
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => setDarkMode(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleChange);
    handleChange();

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [setDarkMode])


  return (
    <DarkModeContext.Provider value={{ darkMode }}>
      {children}
    </DarkModeContext.Provider>
  );

};