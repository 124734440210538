import { ImageConfig } from "konva/lib/shapes/Image";
import { useState, useRef, useEffect } from "react";
import { Layer, Stage, Text, Image } from "react-konva";
import useImage from "use-image";
import LoopingAudio from "../components/LoopingAudio";

export const SplashScreen: React.FC<{ onExit: () => void }> = ({ onExit }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  // const pixelRatio = window.devicePixelRatio || 1;
  const pixelation = 8;
  const FPS = 10;
  const ANIMATION_LENGTH_SECONDS = 5;
  const ANIMATION_LENGTH_FRAMES = FPS * ANIMATION_LENGTH_SECONDS;

  const [sun] = useImage("../sun.gif");
  const [grid] = useImage("../grid.gif");
  const [computer] = useImage("../computer.gif");
  const [frameIndex, setFrameIndex] = useState(0);
  const maxFontSize = Math.min(
    width / pixelation / 6,
    15,
    height / pixelation / 8
  );
  const fontSize =
    Math.min(1, frameIndex / ANIMATION_LENGTH_FRAMES + 0.01) * maxFontSize;

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      const handleResize = () => {
        const [w, h] = [window.innerWidth, window.innerHeight];
        setWidth(w);
        setHeight(h);

        document.querySelectorAll("canvas").forEach((canvas) => {
          const ctx = canvas.getContext("2d");
          if (ctx) ctx.imageSmoothingEnabled = false;
        });
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      window.setInterval(() => {
        setFrameIndex((prev) => prev + 1);
      }, 1000 / FPS);

      window.addEventListener("keydown", onExit);
    }
  }, [onExit]);

  const sunConfig: ImageConfig = {
    image: sun,
    width: (width * 2) / pixelation,
    height: (width * 2) / pixelation,
    x: width / pixelation / 2,
    y: width / pixelation / 2,
    rotation: frameIndex / 5,
    offsetX: width / pixelation,
    offsetY: width / pixelation,
    opacity: Math.min(1, frameIndex / ANIMATION_LENGTH_FRAMES),
  };

  const gridHeight = Math.min(
    (width / pixelation / 880) * 436,
    (height / pixelation) * 0.3
  );

  const gridConfig: ImageConfig = {
    image: grid,
    width: width / pixelation,
    height: gridHeight,
    y:
      height / pixelation -
      gridHeight * Math.min(1, frameIndex / ANIMATION_LENGTH_FRAMES) * 0.7,
  };

  const computerWidth = Math.min(
    width / pixelation,
    (height / pixelation) * 0.8
  );
  const computerHeight = (computerWidth / 880) * 550;

  const computerConfig: ImageConfig = {
    image: computer,
    width: computerWidth,
    height: (computerWidth / 880) * 550,
    y:
      height / pixelation -
      computerHeight * Math.min(1, frameIndex / ANIMATION_LENGTH_FRAMES),
    x: Math.max(0, width / pixelation / 2 - computerWidth / 2),
  };

  return (
    <div
      style={{ width, height }}
      onClick={() => {
        onExit();
      }}
    >
      <LoopingAudio src="/theme.mp3"/>
      <Stage
        width={width / pixelation}
        height={height / pixelation}
        style={{ imageRendering: "pixelated", width, height }}
        className="w-full h-full flex justify-center items-center"
      >
        <Layer>
          <Image {...sunConfig} />
          <Image {...gridConfig} />
          <Image {...computerConfig} />
          <Text
            text="ENDLESS"
            fontSize={fontSize}
            fill="#FAF7E8"
            fontFamily="Benguiat"
            align="center"
            width={width / pixelation}
            y={(0.2 * height) / pixelation}
            stroke={"#281519"}
            strokeWidth={2}
            letterSpacing={-0.5}
          />
          <Text
            text="ADVENTURE"
            fontSize={fontSize}
            fill="#FAF7E8"
            fontFamily="Benguiat"
            align="center"
            width={width / pixelation}
            y={(0.2 * height) / pixelation + fontSize - 1}
            stroke={"#281519"}
            strokeWidth={2}
            letterSpacing={-0.5}
          />
          <Text
            text="ENDLESS"
            fontSize={fontSize}
            fill="#FAF7E8"
            fontFamily="Benguiat"
            align="center"
            width={width / pixelation}
            y={(0.2 * height) / pixelation}
            letterSpacing={-0.5}
          />
          <Text
            text="ADVENTURE"
            fontSize={fontSize}
            fill="#FAF7E8"
            fontFamily="Benguiat"
            align="center"
            width={width / pixelation}
            y={(0.2 * height) / pixelation + fontSize - 1}
            letterSpacing={-0.5}
          />
        </Layer>
      </Stage>
    </div>
  );
};
