import React, { useEffect, useRef } from 'react';

interface LoopingAudioProps {
  src: string;
}


export const LoopingAudio: React.FC<LoopingAudioProps> = ({ src }) => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const bufferSourceRef = useRef<AudioBufferSourceNode | null>(null);

  const fetchAudio = async (url: string) => {
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    audioContextRef.current = audioContext;
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    const bufferSource = audioContext.createBufferSource();
    bufferSource.buffer = audioBuffer;
    bufferSource.loop = true;
    bufferSource.connect(audioContext.destination);
    bufferSourceRef.current = bufferSource;
  };

  useEffect(() => {
    if (src) {
      fetchAudio(src).then(() => {
        bufferSourceRef.current?.start(0);
      }).catch(e => console.error('Error fetching or playing audio:', e));

      return () => {
        bufferSourceRef.current?.stop();
        audioContextRef.current?.close();
      };
    }
  }, [src]);

  return <></>;
};

export default LoopingAudio;
