import { useLocation, useNavigate } from "react-router-dom";

interface SettingsProps {
  exit: () => void;
}

export const TerminalSettings: React.FC<SettingsProps> = ({ exit }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const refresh = () => {
    // Navigate to the current path with a state change to trigger re-render
    navigate(location.pathname, { state: { refresh: true } });
  };

  const newStory = () => {
    const currentPath = location.pathname;
    const basePath = currentPath.substring(0, currentPath.lastIndexOf("/"));
    window.location.href = basePath;
  };

  return (
    <div
      onClick={exit}
      className="fixed w-screen h-screen flex justify-center items-center z-10 top-0 left-0"
    >
      <div className="fixed w-screen h-screen top-0 left-0 bg-background opacity-75"></div>
      <div className="flex flex-col justify-center items-center py-1 px-2 bg-main text-active leading-4 z-50">
        <span>╔════════════════╗</span>
        <div className="flex flex-row w-full justify-between">
          <span>║</span>
          <span>Main Menu</span>
          <span>║</span>
        </div>
        <span>╠════════════════╣</span>
        <div className="flex flex-row w-full justify-between">
          <span>║</span>
          <span>║</span>
        </div>
        <div className="flex flex-row w-full justify-between">
          <span>║</span>
          <button className="text-active" onClick={newStory}>New Story</button>
          <span>║</span>
        </div>
        <div className="flex flex-row w-full justify-between">
          <span>║</span>
          <span>║</span>
        </div>
        <div className="flex flex-row w-full justify-between">
          <span>║</span>
          <button className="text-active" onClick={() => window.location.reload()}>Refresh</button>
          <span>║</span>
        </div>
        <div className="flex flex-row w-full justify-between">
          <span>║</span>
          <span>║</span>
        </div>
        <span>╚════════════════╝</span>
      </div>
    </div>
  );
};
